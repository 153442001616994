import { commaSeparated } from "@/store/utils";

/**
 * @param {BigInt} number
 * @returns {object[]}
 */
export function convertTo9(number) {
  const steps = [];

  let iteration = 1n;
  while (true) {
    const addition = getSumString(number);
    const sum = getSumEval(addition);

    steps.push(getStepObj(iteration, `${addition} = ${sum}`, "Sum the digits of this number"));

    if (sum === 9n) {
      return steps;
    }

    const additionNew = getSumString(sum);
    const sumNew = getSumEval(additionNew);
    if (sumNew === 9n) {
      iteration++;

      steps.push(getStepObj(iteration, `${additionNew} = ${sumNew}`, "Sum the digits of the previous sum result"));
      return steps;
    }

    iteration++;
    const subtraction = number - sum;

    steps.push(
      getStepObj(iteration, `${number} - ${sum} = ${subtraction}`, "Subtract the new number from the previous number")
    );
    if (subtraction === 9n) {
      return steps;
    }

    iteration++;
    number = subtraction;
  }
}

/**
 * @param {BigInt} i
 * @param {string} calculation
 * @param {string} tutorial
 * @return {{calculation: string, iteration: string, tutorial: string}}
 */
function getStepObj(i, calculation, tutorial) {
  return {
    iteration: i,
    calculation: commaSeparated(calculation),
    tutorial
  };
}

/**
 * @param {string|BigInt} number
 * @returns {string}
 */
function getSumString(number) {
  return number.toString().split("").join("+");
}

/**
 * @param {string} additionString
 * @returns {BigInt}
 */
function getSumEval(additionString) {
  return additionString.split("+").reduce((sum, digit) => sum + BigInt(digit), 0n);
}
